import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from 'gatsby-source-prismic-graphql'
import { Grid } from '@material-ui/core'

const RichTextByPrismic = props => {
  const { slice } = props
  if (!slice) return null
  return (
    <Grid container>
      {slice.fields.map(({ contenido_texto_enriquecido: richTextContent }) => (
        <Grid item xs="auto">
          <RichText render={richTextContent} linkResolver={linkResolver} />
        </Grid>
      ))}
    </Grid>
  )
}

RichTextByPrismic.propTypes = {}

export default RichTextByPrismic
