import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'

const TituloRelacionados = props => {
  return <RichText render={props.slice.primary.titulo} />
}

TituloRelacionados.propTypes = {}

export default TituloRelacionados
