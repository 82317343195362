import React from 'react'
import PropTypes from 'prop-types'
import CardCategory from './CardCategory'

const CardCategoryByPrismicSlice = props => {
  const {
    slice: {
      primary: {
        imagen: { url: imageUrl },
        titulo_de_tarjeta_categoria,
      },
    },
  } = props
  return (
    <CardCategory
      titulo={titulo_de_tarjeta_categoria[0].text}
      imageUrl={imageUrl}
      link="/"
    />
  )
}

CardCategoryByPrismicSlice.propTypes = {}

export default CardCategoryByPrismicSlice
