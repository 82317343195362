import React from 'react'
import PropTypes from 'prop-types'

const Imagen = props => {
  return (
    <img
      src={props.slice.primary.imagen.url}
      style={{ width: 'auto', maxWidth: '100%' }}
    />
  )
}

Imagen.propTypes = {}

export default Imagen
