import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'

const Texto = props => {
  return <RichText render={props.slice.fields[0].texto_descriptivo} />
}

Texto.propTypes = {}

export default Texto
