import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Rating from '@material-ui/lab/Rating'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 300,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column nowrap',
    margin: '0',
    '& .card-media': {
      maxHeight: 250,
      height: '100%',
      width: 'auto',
      borderRadius: 5,
    },
    '& .card-title': {
      textAlign: 'center',
      display: '-webkit-box',
      '-webkitLineClamp': 2,
      '-webkitBoxOrient': 'vertical',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '1.5rem',
  },
}))

const TarjetaProducto = props => {
  const classes = useStyles()
  const { imagenUrl, titulo, estrellasValoracion, enlace } = props
  return (
    <a href={enlace} target="_blank" rel="noopener norefferer">
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <CardMedia className="card-media" image={imagenUrl} component="img" />
          <CardHeader title={titulo} className="card-title" />
          {typeof estrellasValoracion === 'number' && (
            <Rating value={estrellasValoracion} readOnly></Rating>
          )}
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ShoppingCartIcon />}
          >
            Comprar en Amazon
          </Button>
        </CardActions>
      </Card>
    </a>
  )
}

export default TarjetaProducto
