import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Titulo from './Titulo'

const useStyles = makeStyles({
  root: {
    background: props =>
      `linear-gradient(90deg, ${props.palette.primary.dark} 0%, ${props.palette.primary.main} 50%, ${props.palette.primary.light} 100%)`,
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px grey',
    color: 'white',
    display: 'flex',
    padding: props => (props.titulo ? '.1rem .7rem' : '0 1rem'),
    justifyContent: 'center',
    textTransform: 'uppercase',
    fontSize: props => (props.titulo ? '1.2rem' : '0.7rem'),
    margin: '3rem 0 2rem 0',
  },
})

const TituloBanda = props => {
  const theme = useTheme()
  const classes = useStyles({ ...theme, ...props })
  const { slice, titulo } = props
  return (
    <div className={classes.root}>
      {titulo ? <h2>{titulo}</h2> : <Titulo slice={slice} />}
    </div>
  )
}

TituloBanda.propTypes = {}

export default TituloBanda
