import React from "react"
import Grid from "@material-ui/core/Grid"
// import PropTypes from "prop-types"
import LogoHeader from "../atoms/LogoHeader"

const Header = props => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <LogoHeader />
      </Grid>
    </Grid>
  )
}

// Header.propTypes = {}

export default Header
