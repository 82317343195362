import React from "react"
import Grid from "@material-ui/core/Grid"
// import PropTypes from "prop-types"
import FooterPrincipal from "../molecules/FooterPrincipal"

const Footer = props => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <FooterPrincipal />
      </Grid>
    </Grid>
  )
}

// Footer.propTypes = {}

export default Footer
