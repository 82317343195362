import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
// import Img from "gatsby-image"
import { withPreview } from 'gatsby-source-prismic-graphql'
import { useTheme } from '@material-ui/core'
// import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"

const queryLogoHeader = graphql`
  query {
    prismic {
      header(uid: "header", lang: "es-es") {
        logotipo
        background_color
      }
    }
  }
`

const LogoHeader = ({ data }) => {
  if (!!data && data.prismic && !data.prismic.header) return null
  const { logotipo, background_color: backgroundColor } = data.prismic.header
  const theme = useTheme()
  return (
    <div
      className="header"
      style={{
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Link to="/">
        <img
          style={{
            maxHeight: '120px',
            width: 'auto',
            maxWidth: '100%',
          }}
          src={logotipo.url}
          alt={logotipo.alt}
        />
      </Link>
    </div>
  )
}

const LogoHeaderWithQuery = () => (
  <StaticQuery
    query={queryLogoHeader}
    render={withPreview(
      data => (
        <LogoHeader data={data} />
      ),
      queryLogoHeader
    )}
  />
)
export default LogoHeaderWithQuery
