import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Titulo from './Titulo'

const useStyles = makeStyles({
  root: {
    background: ({ palette }) => palette.secondary.main,
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px grey',
    color: ({ palette }) => palette.secondary.contrastText,
    padding: '0 1rem',
  },
})

const TituloConIcono = props => {
  const theme = useTheme()

  const classes = useStyles(theme)
  return (
    <div className={classes.root}>
      <Titulo slice={props.slice} />
    </div>
  )
}

TituloConIcono.propTypes = {}

export default TituloConIcono
