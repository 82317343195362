import React from 'react'
import ProductosByPrismic from '../organisms/ProductosByPrismic'
import Texto from '../atoms/Texto'
import RichTextByPrismic from '../atoms/slicesByPrismic/RichTextByPrismic'
import TituloConIcono from '../atoms/TituloConIcono'
import Enumeracion from '../atoms/Enumeracion'
import Imagen from '../atoms/Imagen'
import TituloRelacionados from '../atoms/TituloRelacionados'
import CardCategoryByPrismicSlice from '../molecules/CardCategoryByPrismicSlice'
import TituloBanda from '../atoms/TituloBanda'
import Titulo from '../atoms/Titulo'
import TextoDestacadoByPrismic from '../atoms/slicesByPrismic/TextoDestacadoByPrismic'
import GrupoCategoriasByPrismic from '../molecules/slicesByPrismic/GrupoCategoriasByPrismic'

const SliceZone = ({ sliceZone }) => {
  const sliceComponents = {
    titulo_y_descripcion_standar: Titulo,
    texto_enriquecido: RichTextByPrismic,
    texto_basico: Texto,
    tarjeta_de_producto: ProductosByPrismic,
    titulo_con_icono: TituloConIcono,
    titulo_banda: TituloBanda,
    texto_con_enumeracion: Enumeracion,
    imagen: Imagen,
    titulo_de_relacionados: TituloRelacionados,
    tarjeta_de_categoria: CardCategoryByPrismicSlice,
    texto_destacado: TextoDestacadoByPrismic,
    grupo_de_categorias: GrupoCategoriasByPrismic,
  }

  const sliceZoneContent = sliceZone.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.type]
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice-${index}`} />
    }
    return slice.type
  })

  return <main className="container">{sliceZoneContent}</main>
}

export default SliceZone
