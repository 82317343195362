import React from 'react'
import PropTypes from 'prop-types'
import CardCategory from './CardCategory'
import { Grid } from '@material-ui/core'

const GrupoCategorias = ({ categorias }) => {
  return (
    <Grid container spacing={2}>
      {categorias.map(({ titulo, imageUrl, link }) => (
        <Grid key={titulo + imageUrl} item xs="auto">
          <CardCategory titulo={titulo} imageUrl={imageUrl} link={link} />
        </Grid>
      ))}
    </Grid>
  )
}

GrupoCategorias.propTypes = {}

export default GrupoCategorias
