import React from 'react'
import { Grid } from '@material-ui/core'
import { RichText, Link } from 'prismic-reactjs'
import TarjetaProducto from '../molecules/TarjetaProducto'

const ProductosByPrismic = props => {
  if (!props.slice) return null
  const {
    slice: { fields },
  } = props
  return (
    <Grid container spacing={2} justify="center">
      {fields.map(({ titulo, imagen, enlace_afiliados: enlaceAfiliados }) => (
        <Grid key={enlaceAfiliados + imagen} item xs="auto">
          <TarjetaProducto
            imagenUrl={imagen && imagen.url}
            titulo={RichText.asText(titulo)}
            enlace={Link.url(enlaceAfiliados)}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default ProductosByPrismic
