import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'

const Enumeracion = props => {
  return <RichText render={props.slice.primary.texto_enumeracion} />
}

Enumeracion.propTypes = {}

export default Enumeracion
