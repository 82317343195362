import React from 'react'
import PropTypes from 'prop-types'
import GrupoCategorias from '../GrupoCategorias'
import { RichText } from 'prismic-reactjs'

const GrupoCategoriasByPrismic = props => {
  const {
    slice: { fields },
  } = props
  const grupoCategoriasFormatter = field => {
    const {
      categoria: {
        titulo_del_documento,
        subtitulo_del_documento,
        imagen_destacada,
        _meta: { uid },
      },
    } = field
    const categoria = {
      imageUrl: imagen_destacada.url,
      titulo: RichText.asText(titulo_del_documento),
      link: `/${uid}`,
    }
    return categoria
  }
  const grupoCategorias = Array.isArray(fields)
    ? fields.map(grupoCategoriasFormatter)
    : []
  return <GrupoCategorias categorias={grupoCategorias} />
}

GrupoCategoriasByPrismic.propTypes = {}

export default GrupoCategoriasByPrismic
