import React from 'react'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import LaunchIcon from '@material-ui/icons/Launch'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    maxWidth: 345,
    minWidth: 150,
    minHeight: 100,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    textTransform: 'uppercase',
    fontSize: '.7rem',
  },
}))

const CardCategory = ({ titulo, imageUrl, link }) => {
  const classes = useStyles()
  const cardCategoryContent = (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h3" variant="h3" className={classes.title}>
            {titulo}
          </Typography>
          {/* <Typography variant="subtitle1" color="textSecondary">
            Subtitulo
          </Typography> */}
        </CardContent>
        <div className={classes.link}>
          <IconButton>
            <LaunchIcon />
          </IconButton>
          Ver {titulo}
        </div>
      </div>
      <CardMedia className={classes.cover} image={imageUrl} title="titulo" />
    </Card>
  )
  return link ? (
    <Link to={link} className={classes.rootWithLink}>
      {cardCategoryContent}
    </Link>
  ) : (
    cardCategoryContent
  )
}

export default CardCategory
