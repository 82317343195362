import React from 'react'
import PropTypes from 'prop-types'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'
import { Grid } from '@material-ui/core'
import RichText from './slicesByPrismic/RichText'

const TextoDestacado = ({ texto }) => {
  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} md={8}>
        <Grid container justify="center">
          <Grid item xs="auto">
            <p
              style={{
                fontSize: '1.5rem',
                textAlign: 'center',
                fontColor: 'gray',
              }}
            >
              <FormatQuoteIcon />
              {typeof texto === 'string' ? texto : <RichText text={texto} />}
              <FormatQuoteIcon />
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

TextoDestacado.propTypes = {}

export default TextoDestacado
