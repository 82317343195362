import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'

const Titulo = props => {
  return <RichText render={props.slice && props.slice.primary.titulo} />
}

Titulo.propTypes = {}

export default Titulo
