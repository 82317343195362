import React from 'react'
import PropTypes from 'prop-types'
import TextoDestacado from '../TextoDestacado'

const TextoDestacadoByPrismic = props => {
  const {
    slice: {
      primary: { texto },
    },
  } = props
  return <TextoDestacado texto={texto}></TextoDestacado>
}

TextoDestacadoByPrismic.propTypes = {}

export default TextoDestacadoByPrismic
