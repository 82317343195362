import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { withPreview, linkResolver } from 'gatsby-source-prismic-graphql'
import Grid from '@material-ui/core/Grid'
import { RichText } from 'prismic-reactjs'

const footerPrincipalQuery = graphql`
  query {
    prismic {
      footer_principal(uid: "footer-principal", lang: "es-es") {
        fontcolor
        background_color
        creditos
        aviso_legal {
          _linkType
          ... on PRISMIC_Pagina_legal {
            _meta {
              uid
              type
            }
            titulo
          }
        }
        politica_de_privacidad {
          _linkType
          ... on PRISMIC_Pagina_legal {
            _meta {
              type
              uid
            }
            titulo
          }
        }
        politica_de_cookies {
          _linkType
          ... on PRISMIC_Pagina_legal {
            _meta {
              type
              uid
            }
            titulo
          }
        }
      }
    }
  }
`

const FooterPrincipalDisplay = ({ color, backgroundColor, credits, links }) => {
  return (
    <Grid
      container
      className="header"
      style={{ backgroundColor, color }}
      justify="space-around"
      alignItems="center"
    >
      <Grid item xs="auto">
        <Grid container spacing={2}>
          {links.map(link => (
            <Grid key={JSON.stringify(link._meta)} item xs="auto">
              <Link
                to={linkResolver(link._meta)}
                style={{ color: 'white', fontSize: '.4rem' }}
              >
                <RichText render={link.titulo} linkResolver={linkResolver} />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs="auto">
        <p>© {credits.text}</p>
      </Grid>
    </Grid>
  )
}

const FooterPrincipal = ({ data }) => {
  if (!!data && data.prismic && !data.prismic.footer_principal) return null
  const {
    background_color: backgroundColor,
    fontcolor,
    creditos,
    aviso_legal: avisoLegal,
    politica_de_privacidad: politicaDePrivacidad,
    politica_de_cookies: politicaDeCookies,
  } = data.prismic.footer_principal
  const [credits] = creditos
  return (
    <FooterPrincipalDisplay
      color={fontcolor}
      backgroundColor={backgroundColor}
      credits={credits}
      links={[avisoLegal, politicaDePrivacidad, politicaDeCookies]}
    />
  )
}

const FooterPrincipalWithQuery = () => (
  <StaticQuery
    query={footerPrincipalQuery}
    render={withPreview(
      data => (
        <FooterPrincipal data={data} />
      ),
      footerPrincipalQuery
    )}
  />
)

export default FooterPrincipalWithQuery
