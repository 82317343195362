import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { useTheme } from '@material-ui/core'
import styled from '@emotion/styled'
import css from '@emotion/css'
import { Global } from '@emotion/core'
import globalStyles from 'styles/global'
import typeStyles from 'styles/typography'
import dimensions from 'styles/dimensions'
import Footer from 'components/organisms/Footer'
import Header from 'components/organisms/Header'
import 'styles/fonts.scss'
import CookieUserConsent from './CookieUserConsent'
import { Helmet } from 'react-helmet'

const LayoutContainer = styled.div`
  max-width: ${dimensions.maxwidthDesktop}px;
  padding-left: ${dimensions.paddingHorizontalDesktop}em;
  padding-right: ${dimensions.paddingHorizontalDesktop}em;
  margin: 0 auto;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    padding-left: ${dimensions.paddingHorizontalTablet}em;
    padding-right: ${dimensions.paddingHorizontalTablet}em;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    padding-left: ${dimensions.paddingHorizontalMobile}em;
    padding-right: ${dimensions.paddingHorizontalMobile}em;
  }

  .Layout__content {
    padding-bottom: 5em;
  }
`

const Layout = ({ children }) => {
  const theme = useTheme()
  const themeStyles = css`
    * {
      &::selection {
        background: ${theme.palette.secondary.dark};
        color: white;
      }
    }
  `
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Header />
          <Helmet>
            <script
              data-ad-client="ca-pub-9317353390638899"
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
            ></script>
          </Helmet>
          <div className="Layout">
            <LayoutContainer className="div">
              <Global
                styles={[globalStyles, typeStyles, themeStyles]}
                theme={{ test: 'a' }}
              />
              <main className="Layout__content">{children}</main>
            </LayoutContainer>
          </div>
          <Footer />
          <CookieUserConsent />
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
