import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from 'gatsby-source-prismic-graphql'

const RichTextComponent = ({ render, text }) => {
  return text ? (
    RichText.asText(text)
  ) : (
    <RichText render={render} linkResolver={linkResolver} Component="div" />
  )
}

RichTextComponent.propTypes = {}

export default RichTextComponent
