import css from '@emotion/css'
import colors from 'styles/colors'
import dimensions from 'styles/dimensions'

const globalStyles = css`
  html,
  body,
  #root {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
  }
  body,
  a {
    cursor: url('https://images.prismic.io/reutilizable/c9a3e3b3-8a19-4e23-ac81-003a288dcddd_HOJA+VERDE+40X40.svg?auto=compress,format'),
      auto;
  }
  a &:hover {
    cursor: url('https://images.prismic.io/reutilizable/e7792cf7-a20d-42da-a5ba-9990bef6af1a_HOJA+ROJA+40X40.svg?auto=compress,format'),
      auto;
  }
  body {
    width: 100%;
    margin: 0 auto;
    font-size: 16px;
    line-height: 1.5;
    color: ${colors.grey900};
    -webkit-font-smoothing: antialiased;

    @media (max-width: ${dimensions.maxwidthMobile}px) {
      font-size: 14px;
    }

    * {
      box-sizing: border-box;
    }
  }

  /*
    A workaround for forcing accessibility wrappers
    to have a 100% height.
    Reach Router issue here: https: //github.com/reach/router/issues/63
    */
  #___gatsby,
  div[role='group'][tabindex] {
    height: 100%;
    min-height: 100% !important;
  }
`

export default globalStyles
