import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'gatsby'
import { useTheme } from '@material-ui/core'

const CookieUserConsent = () => {
  const theme = useTheme()

  const buttonGeneralStyle = {
    borderRadius: '6px',
    color: '#FFF',
  }
  const buttonStyle = {
    padding: '.7rem 3rem',
    background: theme.palette.primary.main,
    fontWeight: 'bold',
    ...buttonGeneralStyle,
  }
  const declineButtonStyle = {
    padding: '.5rem 2rem',
    background: '#00000000',
    border: '1px solid #FFF',
    ...buttonGeneralStyle,
  }
  const onAcceptCookies = () => {
    location.reload()
  }
  return (
    <CookieConsent
      buttonText="ACEPTO"
      declineButtonText="Rechazo"
      enableDeclineButton
      cookieName="gatsby-gdpr-google-analytics"
      onAccept={onAcceptCookies}
      style={{
        padding: '1.5rem',
      }}
      buttonStyle={buttonStyle}
      declineButtonStyle={declineButtonStyle}
    >
      <img
        style={{ maxWidth: '1rem', color: 'white', marginRight: '.5rem' }}
        src="https://images.prismic.io/reutilizable/f0f0e53b-4b9b-429e-8c4a-dc8a328e717c_galleta+%282%29.svg?auto=compress,format"
      />
      Para poder mejorar la experiencia web este sitio utiliza cookies. Tienes
      más información en la{' '}
      <Link
        to="/politica-de-cookies"
        style={{ color: '#EEE', textDecoration: 'underline' }}
      >
        página de cookies
      </Link>
    </CookieConsent>
  )
}

export default CookieUserConsent
